<template>
	<div class="w-full relative">
		<div class="flex justify-between items-center">
			<h1 class="text-xl font-extrabold sm:text-2xl">Перечень медицинских организаций текущего года</h1>
			<div class="flex items-center">
				<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
			</div>
		</div>
		<div class="table-respons t-overflow rounded-t-lg overflow-x-auto bg-white" :style="includedMO.length > 9 ? 'height: calc(100vh - 320px)' : ''">
			<table :style="includedMO.length > 0 ? 'min-height: 550px' : ''">
				<thead>
					<tr :class="{ tr1__sticky: rowsVisible > 5 }">
						<th rowspan="2" style="padding: 0" class="text-center">П/П</th>
						<th rowspan="2" class="th-sort">
							<div class="flex">
								<p class="cursor-pointer" @click="switchFilterVisibility(0)">Наименование МО</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[0].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								:filterOpen="filterOpen"
								position="left"
								:id-filter="0"
								:param="params"
								@query="getData"
								:fields="fields"
								:item="fields[0]"
							/>
						</th>
						<th colspan="5" class="text-center">Количество заполненных анкет</th>
					</tr>
					<tr :class="{ tr2__sticky: rowsVisible > 5 }">
						<th>
							<div class="flex">
								<p class="cursor-pointer" @click="switchFilterVisibility(1)">Общее количество</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[1].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="1" :param="params" @query="getData" :fields="fields" :item="fields[1]" />
						</th>
						<th>
							<div class="flex">
								<p class="cursor-pointer" @click="switchFilterVisibility(2)">Амбулаторные</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[2].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="2" :param="params" @query="getData" :fields="fields" :item="fields[2]" />
						</th>
						<th>
							<div class="flex">
								<p class="cursor-pointer" @click="switchFilterVisibility(3)">Стационарные</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[3].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="3" :param="params" @query="getData" :fields="fields" :item="fields[3]" />
						</th>
						<th>
							<div class="flex">
								<p class="cursor-pointer" @click="switchFilterVisibility(4)">Санаторно-курортные</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[4].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="4" :param="params" @query="getData" :fields="fields" :item="fields[4]" />
						</th>
						<th>
							<div class="flex">
								<p class="cursor-pointer" @click="switchFilterVisibility(5)">Психиатрические</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === fields[5].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="5" :param="params" @query="getData" :fields="fields" :item="fields[5]" />
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, ind) in includedMO" :key="item.medicalOrgId">
					<tr :class="{ 'bg-gray-30': item.medicalOrgId === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
						<td style="padding: 10px; text-align: center">
							<span v-if="params.currentPage === 0">
								{{ ind + 1 }}
							</span>
							<span v-if="params.currentPage > 0">
								{{ params.currentPage * rowsVisible + (ind + 1) }}
							</span>
						</td>
						<td>
							<div class="flex items-center mb-2">
								<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
								<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
							</div>
							<p :class="{ underline: item.hasBranch }">{{ item.name }}</p>
						</td>
						<td>
							<p>{{ item.allCount }}</p>
						</td>
						<td>
							<p>{{ item.ambulatorCount }}</p>
						</td>
						<td>
							<p>{{ item.stacionarCount }}</p>
						</td>
						<td>
							<p>{{ item.sanatoriatCount }}</p>
						</td>
						<td>
							<p>{{ item.psychiatricCount }}</p>
						</td>
					</tr>
					<template v-if="item.medicalOrgId === opened">
						<tr v-for="(subItem, ind1) in item.branches" :key="subItem.medicalOrgId" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
							<td style="padding: 10px; text-align: center">
								{{ ind1 + 1 }}
							</td>
							<td>
								<div class="flex items-center mb-2">
									<div v-if="subItem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
									<div v-if="subItem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
								</div>
								<p>{{ subItem.name }}</p>
							</td>
							<td>
								<p>{{ subItem.allCount }}</p>
							</td>
							<td>
								<p>{{ subItem.ambulatorCount }}</p>
							</td>
							<td>
								<p>{{ subItem.stacionarCount }}</p>
							</td>
							<td>
								<p>{{ subItem.sanatoriatCount }}</p>
							</td>
							<td>
								<p>{{ subItem.psychiatricCount }}</p>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<div v-if="!loadingTable && includedMO.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
				<span class="w-full">Данных нет...</span>
			</div>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />

		<PopUp :open="openExport" size="700px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col overflow-auto">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="upLoadExcel(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
import Icons from '@/components/Icons.vue';
// Глобальные данные стора
import { getters, mutations, methods, actions } from '@/store/store';
// Локальные данные стора
import localActions from '../store/actions';
import loadingComponent from '@/components/Loading.vue';
import PopUp from '@/components/PopUp.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';

export default {
	name: 'TableProtocols',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination,
		Icons,
		CheckboxGroup,
		PopUp
	},
	data() {
		return {
			opened: null,
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			openExport: false,
			openDelet: false,
			open: false,
			filterOpen: null,
			filterBy: null,
			popupTitle: '',
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				columns: [],
				pageSize: this.$MaxPageSize,
				currentPage: 0
			},
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Общее количество',
					fieldName: 'allCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulatorCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionarCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'sanatoriatCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatricCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			fieldsDownloadExcell: [
				{ title: 'Филиал', id: 'Branch', checked: true },
				{ title: 'Наименование МО', id: 'Name', checked: true },
				{ title: 'Количество заполненных анкет: Общее количество', id: 'AllCount', checked: true },
				{ title: 'Количество заполненных анкет: Амбулаторные', id: 'AmbulatorCount', checked: true },
				{ title: 'Количество заполненных анкет: Стационарные', id: 'StacionarCount', checked: true },
				{ title: 'Количество заполненных анкет: Санаторно-курортные', id: 'SanatoriatCount', checked: true },
				{ title: 'Количество заполненных анкет: Психиатрические', id: 'PsychiatricCount', checked: true }
			],
			allRows: [],
			includedMO: [],
			loadToExcell: false,
			loadingTable: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...localActions,
		...methods,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalOrgId) {
					this.opened = null;
				} else {
					this.opened = item.medicalOrgId;
				}
			}
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		upLoadExcel(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'MedicalOrgs/GetIncludedMOExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Перечень медицинских организаций текущего года.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getIncludedMO(this.params).then((res) => {
					this.allRows = res.data.data;
					this.includedMO = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields[0].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});
			} else {
				this.includedMO = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;

		this.getRegionYearData(this.region.id).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params, true);
		});
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 7;
}
.tr2__sticky {
	background-color: #f9fafb;
	top: 60px;
	position: sticky;
	z-index: 7;
}

.table-respons td,
.table-respons th {
	padding: 20px 30px;
	text-align: left;
	vertical-align: center;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
	position: relative;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	min-height: auto;
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons table {
	width: 100%;
}

.table-respons .th-sort {
	text-decoration-line: underline;
	cursor: pointer;
}

.bold td {
	font-weight: 600;
}

.table-respons {
	position: relative;
	background: #fff;
	border-radius: 10px 10px 0 0;
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.table-respons .filial {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #fff;
	display: block;
	width: 110px;
	line-height: 28px;
	margin-bottom: 10px;
	text-align: center;
	background: #a8b1ce;
	border-radius: 4px;
}

.table-respons .active td,
.table-respons .filial-row td {
	border-bottom: 1px solid rgba(168, 177, 206, 0.2);
	border-right: 1px solid rgba(168, 177, 206, 0.2);
}

th.text-center {
	text-align: center;
}

.active {
	background: #f2f5ff;
}

.active .th-sort {
	color: #3377ff;
}

.filial-row {
	background: #f2f5ff;
}
</style>
